<script setup>
import { useCssModule } from 'vue';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';

const $style = useCssModule();

defineProps({
  showActionButtons: {
    type: Boolean,
    default: true,
  },
});

const { registerButtonText } = useDefaultTrial();

const buyNowButtonProps = {
  additionalClassNames: 'tw-flex-grow tw-ml-1',
  displayBlock: true,
  extraLarge: true,
  to: '/buy?source=homepage',
  type: ButtonTypes.homePageBuyNow,
};

const containerClass = [
  'tw-pt-10 md:tw-pt-20 tw-pb-12',
  'tw-bg-cover',
  $style.container,
];

const contentClass = 'md:tw-flex container';

const contentColumnClass = [
  'md:tw-w-3/5 lg:tw-w-1/2',
  'tw-mb-3 lg:tw-ml-24 lg:tw-px-4',
];

const copyClass = [
  'tw-mb-5',
  'tw-text-center md:tw-text-left',
  'tw-text-base tw-leading-tight md:tw-text-2xl md:tw-leading-8',
  'tw-font-light tw-text-white',
];

const imageColumnClass = [
  'tw-flex lg:tw-block',
  'md:tw-w-2/5 lg:tw-w-1/3 lg:tw-px-4',
  'tw-justify-center md:tw-justify-end',
  $style.imageColumn,
];

const startFreeTrialButtonProps = {
  additionalClassNames: 'tw-flex-grow tw-mr-1',
  displayBlock: true,
  extraLarge: true,
  to: 'user/register',
  type: ButtonTypes.allAccess,
};

const titleClass = [
  'tw-mb-2.5 md:tw-mb-8',
  'tw-text-center md:tw-text-left',
  'tw-font-bold tw-text-white',
  $style.title,
];
</script>

<template>
  <div :class="containerClass">
    <div :class="contentClass">
      <div :class="contentColumnClass">
        <h1 :class="titleClass">
          <span class="tw-whitespace-nowrap">Weather Forecasts</span>
          <span class="tw-whitespace-nowrap">& Snow Reports</span>
        </h1>
        <p :class="copyClass">
          OpenSnow is your trusted source for the most accurate weather forecast, snow report,
          high-resolution weather maps, and ski conditions information.
        </p>
        <div
          v-if="showActionButtons"
          class="tw-flex"
        >
          <Button v-bind="startFreeTrialButtonProps">
            <span class="tw-block tw-text-lg tw-font-normal">
              {{ registerButtonText }}
            </span>
            <small class="tw-block tw--mt-1 tw-font-normal tw-text-xs">
              No credit card required
            </small>
          </Button>
          <Button v-bind="buyNowButtonProps">
            <span class="tw-block tw-text-lg tw-font-normal tw-leading-loose">
              Buy Now
            </span>
          </Button>
        </div>
      </div>
      <div :class="imageColumnClass">
        <img
          class="tw-h-full"
          src="https://blizzard.opensnow.com/app-images/500x1004/favorite-locations-alta-highlands-taos-16.png"
        >
      </div>
    </div>
  </div>
</template>

<style module>
.container {
  background:
    linear-gradient(rgb(41 61 82 / 40%), rgb(41 61 82 / 40%)),
    url('https://blizzard.opensnow.com/images/home-page/home-background-2018.jpg');
  background-position: 50%;
  min-height: 90vh;
}

@media (min-width: 768px) {
  .container {
    min-height: 50vh;
  }
}

@media (min-width: 992px) {
  .container {
    min-height: 80vh;
  }
}

.title {
  font-size: 2.4rem;
  line-height: 0.9;
}

@media (min-width: 768px) {
  .title {
    font-size: 2.8rem;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 3.8rem;
  }
}

.imageColumn {
  height: 25rem;
}

@media (min-width: 992px) {
  .imageColumn {
    height: 37.5rem;
  }
}
</style>
